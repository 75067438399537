.topo{
	&.topo-fixed{
		position: absolute;
		width: 100%;

		top: 0;
		left: 0;
		z-index: 199;
	}
	
	padding-top: 25px;
	padding-bottom: 25px;

	background-color: #EB3D00;
	
	
	// &:before{
	// 	content: "";

	// 	display: inline-block;
	// 	width: 100%;
	// 	height: 175px;
		
	// 	position: absolute;
	// 	z-index: -1;

	// 	top: 0;
	// 	left: 0;

		
	// }
	
	.navbar-logo{
		text-align: center;
		margin-top: 0px;
	}
	
	.navbar-toggle{
		position: relative;
		z-index: 300;
		display: inline-block;
		color: #FFF;

		margin: 0;
		padding: 0;

		&.open{
			.icon-circle{
				border-color: $cor-default;
				.fa{
					&:before{
						content: "\f00d";
					}
				}
			}
		}

		.icon-circle{
			position: relative;
			display: inline-block;
			vertical-align: middle;

			width: 50px;
			height: 50px;

			border-radius: 50%;

			border: 3px solid transparent;
			margin-right: 5px;
			
			.fa{
				position: absolute;
				top: 50%;
				left: 50%;

				transform: translateY(-50%) translateX(-50%);

				font-size: 20px;
			}
		}

	}

	.navbar-nav{
		position: fixed;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		display: none;
		opacity: 0;

		z-index: 299;
		background: rgba(#242424, .9);

		margin: 0;
		padding: 0;

		overflow: auto;

		&.in{

			display: flex;
			justify-content: center;
			align-items: center;

			opacity: 1;
			height: 100%;

			transition: opacity 1s;
		}

		#menu{
			li{
				a{
					display: block;
					text-align: center;

					font-family: "montserratblack";
					font-size: 25px;
					color: #FFF;

					margin: 25px 0;
				}

				&:hover,
				&.active{
					a{
						text-decoration: none;
						color: $cor-default;
					}
				}
			}
		}
	}

	.redes-sociais{
		a{
			display: inline-block;
			margin: 0 5px;

			font-size: 18px;

			&:first-child{
				margin-left: 0;
			}

			&:last-child{
				margin-right: 0;
			}

			.fa{
				color: #FFF;
			}
		}
	}
}
