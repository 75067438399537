.rodape{
	padding-top: 80px;

	font-family: "latoregular";
	font-size: 13px;
	color: #999;

	header{
		text-align: center;
		margin: 40px 0 30px;

		.titulo{
			position: relative;
			display: inline-block;

			font-family: "montserratblack";
			font-size: 30px;
			color: #242424;

			@media (min-width: $screen-sm-min){
				&:before,
				&:after{
					content: "";
					
					display: inline-block;
					width: 130px;
					height: 1px;

					position: absolute;
					top: calc(50% - .5px);

					background: #232323;
				}

				&:before{
					right: calc(100% + 20px);
				}
				
				&:after{
					left: calc(100% + 20px);
				}
			}
		}
	}

	h2{
		font-family: "montserratblack";
		font-size: 30px;
		color: #242424;
		margin-bottom: 30px;
	}

	h3{
		font-family: "montserratblack";
		font-size: 15px;
		color: #EB3C00;
	}
	
	.redes-sociais{
		text-align: center;
		margin-top: 30px;

		a{
			display: inline-block;
			margin: 0 5px;

			&:first-child{
				margin-left: 0;
			}

			&:last-child{
				margin-right: 0;
			}

			.fa{
				color: #FFF;
			}
		}
	}

	nav{
		li{
			margin-bottom: 5px;
		}
	}

	.contatos{
		p{
			margin-bottom: 15px;
		}
	}

	.creditos{
		padding: 20px 0;
		margin-top: 30px;
		border-top: 1px solid #EB3B00;

		.logo-gv8{
			float: right;
			margin-left: 10px;
			margin-top: -2.5px;
		}
	}
}