$imgs: '../imagens/estrutural';
$fonts: '../fonts';

$cor-default: rgba(#EB3D00, 1);

$bg-topo: url(#{$imgs}/bg-topo.png) center top no-repeat;

$bg-pacotes: url(#{$imgs}/bg-pacotes.png) center bottom no-repeat rgba(#277BAF, 1);
$before-pacotes: url(#{$imgs}/before-pacotes.png) center top no-repeat;
$after-pacotes: url(#{$imgs}/after-pacotes.png) center top no-repeat;

$bg-sobrenos: url(#{$imgs}/bg-sobrenos.png) center bottom no-repeat;

$bg-newsletter: url(#{$imgs}/bg-newsletter.png) center top no-repeat;

$bg-shows: url(#{$imgs}/bg-shows.png) center bottom repeat-y;
$after-shows: url(#{$imgs}/after-shows.png) center top no-repeat;

$pseudo-stars: url(#{$imgs}/pseudo-stars.png) center top no-repeat;

$before-arrow: url(#{$imgs}/before-arrow.png) center top no-repeat;

$bg-sprite: url(#{$imgs}/sprites.png) no-repeat;