.nav-tabs{
	position: relative;
	border-bottom: none;
	margin:  60px 0;
	padding: 0;

	.pager{
		position: absolute;
		top: 100%;
		
		@media(min-width: $screen-md-min){
			top: 50%;
		}
		
		@media(max-width: $screen-sm-max){
			margin-top: 20px;
			padding: 20px;
			width: 50%;
		}

		margin: 0;
		z-index: 199;

		transform: translateY(-50%);

		&.pager-prev{
			right: 50%;
			@media(min-width: $screen-md-min){
				right: calc(100% + 20px);
			}
		}

		&.pager-next{
			left: 50%;
			@media(min-width: $screen-md-min){
				left: calc(100% + 20px);
			}
		}

		.fa{
			font-size: 30px;
			color: #777;
		}
	}

	ul{
		li{
			text-align: center;
			padding-left: 20px;
			padding-right: 20px;
			@media(min-width: $screen-sm-min){
				border-right: 1px solid #242424;
			}

			a{
				display: block;

				font-family: "latoregular";
				font-size: 13px;
				color: #2B2B2B;

				border-radius: 25px;
				padding: 10px 0;

				.icon{
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
					min-height: 36px;

				}
			}

			&.active,
			&:hover{
				a{
					color: #FFF;
					background: #242424;
				}
			}
		}
	}
}

.lista-newsletters{
	ul{
		li{
			margin: 10px 0;

			a{
				display: block;
				font-family: "montserratextrabold";
				font-size: 20px;
				color: $cor-default;

				.data{
					color: #000;
				}
			}
		}
	}
}

.newsletter-header{
	margin-top: 50px;
	margin-bottom: 80px;
	padding-bottom: 35px;
	border-bottom: 1px solid #E3E3E3;
	
	.data{
		font-family: "latoregular";
		font-size: 15px;
		color: #1E1E1E;
	}

	h2,
	h3{
		font-family: "montserratblack";
	}

	h2{
		margin-top: 10px;
		font-size: 35px;
		color: $cor-default;
	}
	h3{
		font-size: 20px;
		color: #999;
	}
}

.informacoes{
	.info-h{
		margin: 12.5px 0;

		span,
		.info{
			display: inline-block;
			vertical-align: middle;
		}

		span{
			font-family: "latoregular";
			font-size: 20px;
			color: #1E1E1E;
			font-style: italic;
			margin-right: 25px;
		}
	}
}

.parceiro-default{
	margin-bottom: 60px;

	.item-default{

		&:hover{
			.photo{
				opacity: .5;
			}
		}
		.photo{
			position: relative;
			padding-bottom: (198/351)*100%;
			overflow: hidden;

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;

				img{
					border-radius: 6px;
				}
			}
		}

		.dados{
			text-align: center;
			padding-top: 20px;

			.nome{
				font-family: "montserratextrabold";
				color: #000;

				@include resumo(18px, 1.2, 2);

				margin-bottom: 20px;
			}
		}
	}
}

.foto-default{
	margin-bottom: 30px;

	.item-default{
		.photo{
			position: relative;
			padding-bottom: (198/351)*100%;
			overflow: hidden;

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;

				img{
					border-radius: 6px;
				}
			}
		}

		.dados{
			.nome{
				font-family: "montserratblack";
				color: $cor-default;
				font-size: 25px;

				margin-bottom: 30px;
			}

			.desc{
				font-family: "latoregular";
				font-size: 14px;
				color: #656565;
			}
		}
	}
}

.noticia-default{
	margin-bottom: 30px;

	.item-default{
		.photo{
			position: relative;
			padding-bottom: (198/351)*100%;
			overflow: hidden;

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;

				img{
					border-radius: 6px;
				}
			}
		}

		.dados{
			padding-top: 20px;

			.data-hora{
				font-family: "latoregular";
				font-size: 12px;
				color: #656565;

				small{
					color: #999;
				}
			}

			.nome{
				font-family: "montserratblack";
				color: $cor-default;
				font-size: 25px;

				margin-bottom: 20px;
			}

			.desc{
				font-family: "latoregular";
				font-size: 14px;
				color: #656565;
			}
		}
	}
}

.noticia-detalhes{
	margin-bottom: 45px;

	.item-default{
		.photo{
			position: relative;
			padding-bottom: (411/761)*100%;
			margin-bottom: 70px;

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;
			}

			figcaption{
				font-family: "latoregular";
				font-size: 11px;
				color: #333;

				position: absolute;
				width: 100%;
				top: 100%;
				z-index: 19;
				left: 0;
				
				padding: 10px 15px;
				background: #EBEBEB;
			}
		}

		.data-hora{
			font-family: "latoregular";
			font-size: 12px;
			color: #656565;
		}

		.titulo{
			font-family: "montserratbold";
			font-size: 30px;
			color: #333;

			margin-bottom: 30px;
			margin-top: 10px;

			line-height: 110%;
		}

		.text{
			font-family: "latoregular";
			font-size: 13px;
			color: #656565;

			p{
				margin-bottom: 20px;
			}
		}

		.shared{
			margin-top: 30px;

			span,
			.redes-sociais{
				display: inline-block;
				vertical-align: middle;
			}

			span{
				font-family: "latoregular";
				font-size: 13px;
				color: #333;
				font-style: italic;
				margin-right: 5px;
			}

			.redes-sociais{
				a{
					display: inline-block;
					margin: 0 5px;

					.fa{
						font-size: 18px;
						&.fa-facebook{color: #3967A3;}
						&.fa-twitter{color: #04B5EF;}
						&.fa-google-plus{color: #E15541;}
						&.fa-pinterest{color: #D1242C;}
					}
				}
			}
		}
	}
}

.foto-detalhes{
	position: relative;
	margin-bottom: 30px;

	padding-bottom: (198/351)*100%;
	overflow: hidden;

	.picture{
		position: absolute;
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

		img{
			border-radius: 6px;
		}
	}

	&:after{
		content: "\f002";

		font-family: "FontAwesome";
		font-size: 20px;
		color: #4F4949;

		position: absolute;
		top: 50%;
		left: 50%;

		transform: translateY(-50%) translateX(-50%);

		opacity: 0;
	}

	&:hover{
		opacity: .5;

		&:after{
			opacity: 1;
		}
	}
}

.block-noticias{
	.heading,
	.footer{
		background: $cor-default;
	}

	.heading{
		padding: 25px 27px 23px;

		h2{
			font-family: "latoregular";
			font-size: 20px;
			color: #FFF;

			margin: 0;

			strong{
				font-family: "latoblack";
			}
		}
	}

	.footer{
		padding: 10px 0;

		a{
			display: block;
			text-align: center;
			font-family: "latoblack";
			font-size: 17px;
			color: #FFF;
		}
	}

	.content{
		border-left: 1px solid #d7d7d7;
		border-right: 1px solid #d7d7d7;
		padding: 0 27px;
		
		.noticia-block{
			padding: 10px 0;
			
			&:not(:last-child){
				border-bottom: 1px solid #d7d7d7;
			}

			.item-default{
				display: flex;
				justify-content: space-between;
				align-items: center;

				.nro{
					font-family: "latoblack";
					font-size: 50px;
					color: $cor-default;
				}

				.titulo{
					padding-left: 15px;
					font-family: "latoregular";
					font-size: 16px;
					color: #434343;
				}
			}
		}
	}
}

.video-default{
	margin-bottom: 60px;

	.item-default{

		&:hover{
			.photo{
				opacity: .5;
				
				&:after{
					opacity: 1;
				}
			}
		}

		.photo{
			position: relative;
			padding-bottom: (198/351)*100%;
			overflow: hidden;
			border-radius: 6px;

			&:after{
				content: "\f04b";

				font-family: "FontAwesome";
				font-size: 25px;
				color: $cor-default;

				position: absolute;
				top: 50%;
				left: 50%;

				transform: translateY(-50%) translateX(-50%);

				opacity: 0;
			}

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;

			}
		}

		.dados{
			padding-top: 20px;
			
			.nome{
				font-family: "montserratextrabold";
				font-size: 18px;
				color: #000;

				padding-left: 8px;

				border-left: 4px solid $cor-default;
			}
		}
	}
}

#modal-videos{
    .modal-open{
        padding-right: 0 !important;
    }

    .modal-dialog{
        margin: 0;
        height: 100%;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        .modal-content{
            width: 100%;
            background: transparent;
            box-shadow: none;
            border: none;

            @media (min-width: $screen-sm-min){
                max-width: 725px;
            }

            .modal-body{
                button{
                    position: relative;
                    display: inline-block;
                    position: absolute;
                    bottom: 100%;
                    left: 100%;

                    border-radius: 50%;

                    opacity: 1;

                    color: #FFF;
                    padding: 0;
                    margin: 0;

                    &:hover,
                    &:focus{
                        outline: none;
                    }

                    &:before{
                        content: "Fechar";

                        display: inline-block;
                        vertical-align: middle;
                        font-size: 14px;

                        position: absolute;
                        top: calc(50% + 2px);

                        transform: translateY(-50%);
                        right: calc(100% + 10px);
                    }
                }

                iframe{
					width: 100%;
		            @media (min-width: $screen-sm-min){
		                max-width:725px;
                    	height: 485px;
		            }
                }

                .shared{
                    padding: 10px 0;
                    color: #FFF;

                    span, 
                    .sociais{
                        display: inline-block;
                        vertical-align: middle;
                    }

                    span{
                        margin-right: 15px;
                    }
                    .sociais{
                        a{
                            display: inline-block;
                            margin: 0 5px;
                            font-size: 16px;

                            &:first-child{
                                color: #2E5D9A;
                            }
                            &:last-child{
                                color: #00ACE3;
                            }

                            &:hover{
                                color: #00ACE3;
                            }
                        }
                    }
                }
            }
        }
    }
}

.conversao-default{
	margin-bottom: 35px;

	p{
		margin-top: 10px;
		text-align: center;
		font-family: "montserratregular";
		font-size: 16px;
		color: #000;
	}

	.item-default{
		padding: 35px;
		@media (min-width: $screen-sm-min){
			padding: 35px 90px 140px;
		}
		background: rgba(#333, 1);

		.form-conversao{
			text-align: center;

			input{
				font-family: "montserratregular";
				font-size: 16px;
				color: #000;

				margin-right: 25px;

				height: 47px;
				border: none;
				background: #FFF;

				padding-left: 25px;

				width: 100%;
				margin-bottom: 10px;
				@media (min-width: $screen-sm-min){
					max-width: 255px;
					margin-bottom: 0;
				}

				@include placeholdIt(){
					font-family: "montserratregular";
					font-size: 16px;
					color: #000;
				}
			}

			span{
				font-family: "montserratregular";
				font-size: 16px;
				color: #000;
			}
		}

		.bandeiras{
			margin-top: 30px;
			border-top: 1px solid #FFF;
			border-bottom: 1px solid #FFF;

			padding-top: 30px;
			padding-bottom: 55px;

			display: flex;
			justify-content: space-between;
			align-items: center;

			flex-wrap: wrap;
		}
	}
}

.form-contato{
	p{
		font-family: "latoregular";
		font-size: 13px;
		color: #656565;
		font-style: italic;
	}

	.form-control{
		position: relative;
		border-radius: 25px;
		min-height: 49px;
		border: none;
		background: #EBEBEB;

		font-family: "latoregular";
		font-size: 13px;
		color: #656565;

		padding-left: 25px;

		box-shadow: none;
		margin-bottom: 30px;

		select{
			background: transparent;
			border:none;

			position: absolute;
			width: calc(100% - 50px);
			height: 100%;
			top: 0;
			left: 25px;

			padding-left: 25px;

			&:focus{
				outline: none;
			}

			option{
				font-family: "latoregular";
				font-size: 13px;
				color: #656565;
				padding-left: 25px;
			}
		}
	}

	textarea{
		padding-top: 15px;
		resize: none;
	}

	button{
		margin-right: 15px;
	}
}

.pacote-detalhes{
	font-family: "latoregular";
	font-size: 13px;
	color: #333;

	.pacote-header{
		padding-bottom: 50px;
		margin-bottom: 20px;
		border-bottom: 6px solid rgba(#D3D3D3, .6);

		.photo{
			position: relative;
			text-align: center;
			margin-bottom: 20px;

			@media (max-width: $screen-xs-max){
				margin-bottom: 50px;
			}

			.logo-center{
				position: absolute;
				top: 100%;
				left: 50%;

				transform: translateX(-50%) translateY(-50%);
				@media (max-width: $screen-xs-max){
					display: none;
				}
			}

			.nome,
			.data{
				@media (min-width: $screen-sm-min){
					position: absolute;
					bottom: 30px;
				}

				z-index: 19;

				&.nome{
					text-align: center;
					left: 50px;
					font-family: "montserratblack";
					font-size: 50px;
					color: #FFF;
					@media (max-width: $screen-xs-max){
						color: $cor-default;
					}

					line-height: 80%;

					small{
						display: block;
						font-family: "montserratregular";
						font-size: 20px;
					}
				}

				&.data{
					right: 60px;
					
					.dia,
					.mes-ano{
						display: inline-block;
						vertical-align: middle;

						font-family: "montserratblack";
						font-size: 50px;
						color: #FFF;
						@media (max-width: $screen-xs-max){
							color: $cor-default;
						}

						&.mes-ano{
							font-size: 30px;
							line-height: 100%;

							small{
								display: block;
								font-family: "montserratlight";
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}

	.dados-horizontal{
		font-family: "montserratblack";
		font-size: 30px;
		color: #000;

		@media (max-width: $screen-xs-max){
			text-align: left;
			margin: 20px 0;
		}

		span{
			display: inline-block;
			font-size: 15px;
			color: $cor-default;

			margin-right: 5px;
		}

		small{
			font-family: "montserratlight";
			font-size: 13px;
		}
	}

	h1{
		font-family: "montserratblack";
		font-size: 30px;
		color: #000;
	}

	h2{
		font-family: "montserratblack";
		font-size: 15px;
		color: #595959;
	}

	.redes-sociais{
		display: inline-block;
		vertical-align: middle;
		margin-left: 20px;

		a{
			display: inline-block;
			margin: 0 2.5px;

			&:first-child{
				margin-left: 0;
			}

			&:last-child{
				margin-right: 0;
			}
		}
	}
	
	.orcamentos-inline{
		.input-number-custom,
		a{
			display: inline-block;
			vertical-align: middle;
		}

		> a{
			padding-top: 8px;
			padding-bottom: 8px;
		}
	}

	#carousel-miniaturas{
		figure{
			&:hover{
				opacity: .6;
			}
		}
	}
	.input-number-custom{
		display: inline-block;
		margin-right: 15px;
		
		.input-group{
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			a,
			.input-number{
				position: relative;
				display: inline-block;
				vertical-align: middle;
				width: 34px;
				height: 34px;
				background: $cor-default;

				.fa,
				input{
					position: absolute;
				}

				.fa{
					top: 50%;
					left: 50%;

					transform: translateX(-50%) translateY(-50%);
				}
				input{
					width: 45px;
					height: 100%;

					top: 0;
					left: 0;
					
					border: none;
					background: transparent;

					text-align: center;

					font-family: "latoregular";
					font-size: 13px;
					color: #FFF;

					&:focus{
						outline: none;
					}
				}
			}

			.input-number{
				overflow: hidden;
			}

			a{
				color: #FFF;
			}

		}
	}

	.iframe-pacote{
		position: relative;
		padding-bottom: 357px;

		iframe{
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}

#modal-orcamento{
	background: rgba(#242424, .89);

	.modal-dialog{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);

		.modal-content{
			border-radius: 25px;
			padding-left: 45px;
			padding-right: 45px;

			.modal-header{
				border-bottom: none;

				.modal-title{
					font-family: "montserratblack";
					font-size: 25px;
					color: #000;
				}
			}

			.modal-body{
				position: relative;
				button{
					position: absolute;
					top: 100%;
					left: 50%;

					transform: translateX(-50%) translateY(-50%);
				}
				.form-control{
					position: relative;
					border-radius: 25px;
					min-height: 49px;
					border: none;
					background: #EBEBEB;

					font-family: "latoregular";
					font-size: 13px;
					color: #656565;

					padding-left: 25px;

					box-shadow: none;
					margin-bottom: 30px;

					select{
						background: transparent;
						border:none;

						position: absolute;
						width: calc(100% - 50px);
						height: 100%;
						top: 0;
						left: 25px;

						padding-left: 25px;

						&:focus{
							outline: none;
						}

						option{
							font-family: "latoregular";
							font-size: 13px;
							color: #656565;
							padding-left: 25px;
						}
					}
				}
			}
		}
	}
}