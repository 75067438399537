@font-face {
    font-family: 'latoregular';
    src: url('#{$fonts}/lato_regular/fonts/lato-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/lato_regular/fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'latoblack';
    src: url('#{$fonts}/lato_black/fonts/Lato-Black.eot');
    src: url('#{$fonts}/lato_black/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts}/lato_black/fonts/Lato-Black.woff') format('woff'),
        url('#{$fonts}/lato_black/fonts/Lato-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'montserratblack';
    src: url('#{$fonts}/montserrat_black/fonts/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_black/fonts/montserrat-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratregular';
    src: url('#{$fonts}/montserrat_regular/fonts/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_regular/fonts/montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratlight';
    src: url('#{$fonts}/montserrat_light/fonts/montserrat-light-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_light/fonts/montserrat-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratextrabold';
    src: url('#{$fonts}/montserrat_extrabold/fonts/montserrat-extrabold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_extrabold/fonts/montserrat-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'syntharegular';
    src: url('#{$fonts}/syntha_regular/syntha-webfont.woff2') format('woff2'),
         url('#{$fonts}/syntha_regular/syntha-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserratbold';
    src: url('#{$fonts}/montserrat_bold/fonts/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_bold/fonts/montserrat-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}