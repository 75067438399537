.paginacao{
	margin-top:20px;
	margin-bottom:30px;

	display: flex;
	justify-content: center;
	align-items: center;

	> *{
		display: inline-block;

		border-radius: 50%;
		width: 50px;
		height: 50px;
		margin: 0 5px;

		border: 2px solid $cor-default;

		display: flex;
		justify-content: center;
		align-items: center;

		font-family: "montserratextrabold";
		font-size: 20px;
		color: $cor-default;

		background: transparent;
	}

	.active,
	*:hover{
		text-decoration:none;
		color: #FFF;
		background: $cor-default;
	}
}
