.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
}

.owl-nav{
    display: none;
}

.unlistenner{
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.bg-gray{
    background: rgba(#232323, .14);
}

.bg-orange{
    padding-top: 50px;
    padding-bottom: 80px;
    background: rgba(#EB3D00, .7);

    .text{

        p{
            margin-bottom: 15px;
        }
    }

    .container{
        position: relative;
    }
}

.clearfix{
    position: relative;
    
    .absolute-right{
        position: absolute;
        top: 0;
        right: 0;
    }
}

.c-orange{
    color: #EB3C00;
}

.c-gray{
    color: #656565;
}

.c-white{
    color: #FFF;
}

.btn{
    font-family: "latoblack";
    font-size: 13px;
    border-radius: 45px;

    padding: 16px 0;
    text-align: center;

    min-width: 163px;

    &:hover,
    &:focus{
        outline: none;
    }

    &.btn-large{
        font-size: 17px;
        min-width: 257px;
    }
}

.btn-black{
    color: #FFF;
    background: rgba(#2C2C2C, 1);

    &:hover,
    &:focus{
        color: #FFF;
    }
}

.btn-orange{
    color: #FFF;
    background: rgba(#EB3D00, 1);

    &:hover,
    &:focus{
        color: #FFF;
    }
}

.btn-gray{
    color: #FFF;
    background: rgba(#999, 1);

    &:hover,
    &:focus{
        color: #FFF;
    }
}

.w-256{
    min-width: 256px;
}

.mgb-120{
    margin-bottom: 120px;
}

.border-right-gray{
    border-right: 1px solid #E1E1E1;
}

.border-top-gray{
    border-top: 1px solid #E1E1E1;
}

.pdt-20{
    padding-top: 20px;
}