a{
	color:inherit;

	&:hover,
	&:focus{
		text-decoration: none;
		outline: none;
	}
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.page-header{
	border-bottom: none;

	&.pacotes-header{
		text-align: center;
		
		.titulo{
			text-align: left;
			display: inline-block;
			position: relative;

			h1{
				font-family: "montserratblack";
				font-size: 60px;
				color: #FFF;

				small{
					font-family: "montserratregular";
					font-size: 20px;
					display: block;
					color: #FFF;

					margin-bottom: -15px;
				}
			}
			
			@media (min-width: $screen-sm-min){
				&:before,
				&:after{
					content: "";
					
					display: inline-block;
					width: 406px;
					height: 25px;

					position: absolute;
					top: calc(50% - 12.5px);

					background: $pseudo-stars;
				}
				
				&:before{
					right: calc(100% + 20px);
					background-position: bottom center;
				}
				
				&:after{
					left: calc(100% + 20px);
					background-position: top center;
				}
			}
		}
	}

	&.sobre-header{
		h2{
			margin: 0;
			font-family: "montserratextrabold";
			color: #2B2B2B;
			font-size: 0;
		}
		
		h1{
			margin: 0;
			font-family: "montserratextrabold";
			color: #EB3D00;
			font-size: 0;

 			@include stroke(6,#FFF);
		}

		h3{
			margin: 0;
			font-family: "montserratlight";
			font-size: 15px;
			color: #2B2B2B;
		}
	}
}

.internas-header{
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-wrap: wrap;

	margin: 40px 0 20px;
	padding-bottom: 15px;

	border-bottom: 1px solid #000;

	&:before{
		content: "";

		display: inline-block;
		width: 58px;
		height: 5px;

		position: absolute;
		top: calc(100% - 4px);
		left: 0;

		background: $cor-default;

	}

	.titulo{
		h2{
			font-family: "montserratlight";
			font-size: 13px;
			color: #000;

			margin: 0;
		}
		h1{
			font-family: "montserratblack";
			font-size: 40px;
			color: #000;

			margin-top: 10px;
		}
	}

	.breadcrumbs{
		i{
			font-size: 20px;
		}

		> span{
			font-family: "latoregular";
			font-style: italic;
			color: #999;
			font-size: 13px;
		}
		padding-bottom: 10px;

		ul{
			display: inline-block;
			font-family: "latoregular";
			font-size: 13px;
			color: #000;
		
			li{
				display: inline-block;

				&:not(:last-child){
					&:after{
						content: "\f101";

						font-family: "FontAwesome";
						font-size: 12px;
						color: #000;

						display: inline-block;
						margin: 0 2.5px;
					}
				}

				span{
					color: $cor-default;
				}
			}
		}
	}
}