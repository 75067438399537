.icon{
	display: inline-block;
	background: $bg-sprite;
}


.ic-maritimos{
	@include make-icon(27px, 36px, 0 0);
}

.ic-shows{
	@include make-icon(19px, 29px, 0 -62px);
}

.ic-areos{
	@include make-icon(41px, 29px, 0 -118px);
}

.ic-terrestres{
	@include make-icon(39px, 31px, 0 -174px);
}

.ic-whatsapp{
	@include make-icon(19px, 19px, 0 -231px);
}

.ic-o-facebook{
	@include make-icon(75px, 75px, 0 -277px);
}

.ic-o-twitter{
	@include make-icon(75px, 75px, 0 -379px);
}

.ic-o-instagram{
	@include make-icon(75px, 75px, 0 -480px);
}

.ic-o-googleplus{
	@include make-icon(75px, 75px, 0 -582px);
}

.ic-o-flicker{
	@include make-icon(75px, 75px, 0 -684px);
}

.ic-pager-prev{
	@include make-icon(34px, 73px, 0 -786px);
}

.ic-eyes{
	@include make-icon(89px, 59px, 0 -1123px);
}

.ic-pager-next{
	@include make-icon(34px, 73px, 0 -866px);
}

.ic-o-place{
	@include make-icon(130px, 123px, 0 -1200px);
}

.ic-o-phone{
	@include make-icon(130px, 123px, 0 -1344px);
}

.ic-o-email{
	@include make-icon(130px, 123px, 0 -1477px);
}

.ic-o-facebook-small{
	@include make-icon(33px, 33px, 0 -1606px);
}

.ic-o-twitter-small{
	@include make-icon(35px, 35px, 0 -1644px);
}

.ic-o-googleplus-small{
	@include make-icon(33px, 33px, 0 -1686px);
}
