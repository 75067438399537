.banners{
	position: relative;
	
	.pager{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		z-index: 19;

		&.pager-prev{
			left: 25px;
			@media (min-width: $screen-md-min){
				left: calc(50% - 585px);
			}
		}
		&.pager-next{
			right: 25px;
			@media (min-width: $screen-md-min){
				right: calc(50% - 585px);
			}
		}
	}
}

.pacotes{
	position: relative;
	background: $bg-pacotes;

	// &:before{
	// 	content: "";
		
	// 	display: inline-block;
	// 	width: 100%;

	// 	position: absolute;
	// 	left: 0;
	// 	z-index: 21;

	// 	height: 90px;
	// 	bottom: 100%;
	// 	background: $before-pacotes;
	// }

	.pacote-default{
		margin-bottom: 45px;

		.item-default{
			.categoria{
				text-align: center;
				margin-bottom: 30px;

				figure,
				.icon,
				h2{
					display: inline-block;
					vertical-align: middle;
				}

				h2{
					font-family: "latoregular";
					font-size: 13px;
					color: #FFF;

					margin-left: 10px;
				}
			}

			.photo{
				position: relative;
				padding-bottom: (482/253)*100%;
				margin-bottom: 25px;

				.picture{
					position: absolute;
					width: 100%;
					height: 100%;

					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
	
	.content-carousel{
		position: relative;

		.pager{
			position: absolute;
			top: 50%;

			z-index: 29;

			transform: translateY(-50%);

			width: 55px;
			height: 50px;

			border-radius: 6px;

			background: rgba(#FFF, .6);

			display: flex;
			justify-content: center;
			align-items: center;

			&:hover{
				box-shadow: 0 0 7px 5px rgba(#000, .05);
			}

			.fa{
				font-size: 35px;
				color: #777;
			}

			&.pager-prev{
				right: calc(100% - 20px);
			}

			&.pager-next{
				left: calc(100% - 20px);
			}
		}
	}
}

.sobre-nos{
	padding-top: 40px;
	padding-bottom: 130px;
	background: $bg-sobrenos;
	background-size: cover;

	font-family: "latoregular";
	font-size: 16px;
	color: #535353;
}

.newsletter{
	position: relative;
	z-index: 22;
	padding-top: 100px;
	padding-bottom: 30px;
	margin-top: -70px;
	background: $bg-newsletter;

	span{
		font-family: "latoregular";
		font-size: 12px;
		color: #242424;
	}

	.form-newsletter{
		border-radius: 25px;
		background: #FFF;
		
		input,
		button{
			display: inline-block;
			vertical-align: middle;
			
			border: none;
			background: transparent;
			margin: 0;

			min-height: 47px;

			&:hover,
			&:focus{
				text-decoration: none;
				outline: none;
			}
		}

		input{
			width: 100%;
			max-width: calc(100% - 125px);
			
			@media(min-width: $screen-sm-min){
				max-width: calc(100% - 215px);
			}
			padding-left: 35px;

			font-family: "montserratextrabold";
			font-size: 14px;
			color: #999;

			@include placeholdIt(){
				font-family: "montserratextrabold";
				font-size: 14px;
				color: #999;
			}
		}

		button{
			float: right;
			border-top-right-radius: 25px;
			border-bottom-right-radius: 25px;
			
			width: 100%;
			max-width: 125px;
			
			@media(min-width: $screen-sm-min){
				max-width: 195px;
			}
			text-align: center;
			background: #EB3D00;

			font-family: "montserratextrabold";
			font-size: 14px;
			color: #FFF;
		}
	}
}

.shows{
	position: relative;
	padding-top: 40px;
	padding-bottom: 40px;
	background: $bg-shows;

	&:after{
		content: "";
		
		display: inline-block;
		width: 100%;

		position: absolute;
		left: 0;
		z-index: 21;
		
		height: 100px;
		top: 100%;
		background: $after-shows;
	}

	.before-arrow{
		position: relative;
		&:before{
			content: "";

			display: inline-block;
			width: 82px;
			height: 133px;

			position: absolute;
			top: 20px;
			right: 100%;

			background: $before-arrow;
		}
	}

	h2{
		position: relative;
		font-family: "montserratblack";
		font-size: 30px;
		color: #FFF;
		margin: 0;

		&:first-child{
			display: inline-block;
			
			&:after{
				content: "";
				width: 320px;
				height: 1px;
				background: #FFF;

				position: absolute;
				top: calc(50% + 2px);
				left: 110%;
			}
		}

		.fa,
		.icon{
			display: inline-block;
			vertical-align: middle;
		}
	}

	.content-carousel{
		position: relative;

		.pager{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&.pager-prev{
				right: calc(100% + 30px);
			}
			&.pager-next{
				left: calc(100% + 30px);
			}
		}
	}
}

.show-default{
	margin-bottom: 30px;
	
	.item-default{
		.photo{
			position: relative;
			padding-bottom: 100%;

			.picture{
				position: absolute;
				width: 100%;
				height: 100%;

				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.dados{
			font-family: "montserratblack";
			font-size: 20px;
			color: #FFF;

			&.dados-internas{
				color: $cor-default;


				.info{
					border-color: #000;
					color: #000;
				}

				a{
					&:hover{
						color: $cor-default;
					}
				}
			}

			span{
				display: inline-block;
				font-family: "latoregular";
				font-size: 15px;
				color: #242424;

				margin-right: 5px;
			}

			.valor{
				display: inline-block;

				font-family: "montserratblack";
				font-size: 30px;
				color: #242424;
			}

			.info{
				margin-top: 15px;
				margin-bottom: 20px;
				padding: 10px 0;
				border-top: 1px solid #FFF;
				border-bottom: 1px solid #FFF;
				
				font-family: "latoregular";
				font-size: 13px;
				color: #FFF;

				text-align: center;
			}

			a{
				display: block;
				text-align: center;
				font-size: 15px;

				&:hover{
					color: #FFF;
					text-decoration: underline;
				}
			}
		}
	}
}